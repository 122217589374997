import Vue from "vue";
import App from "./AppMain.vue";
import axios from "@/plugins/axios";
import router from "./router";
import store from "./store";
import vuetify, { basicTheme } from "./plugins/vuetify";

import "./plugins/prototype";
import "./plugins/vue-cookies";
import "./plugins/vue-dayjs";
import "./plugins/vue-excel";
import "./plugins/vue-echarts";
import "./plugins/vue-handle-error";
import "./plugins/vue-meta";
import "./plugins/vue-methods-geolocation";
import "./plugins/vue-methods-shop";
import "./plugins/vue-youtube";
import "./plugins/vuetify-draggable-treeview";

// Swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

// VueScrollactive
import VueScrollactive from "vue-scrollactive";
Vue.use(VueScrollactive);

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

if (process.env.NODE_ENV == "production" && location.protocol != "https:") location.protocol = "https:";

new Vue({
    router,
    store,
    vuetify: vuetify(basicTheme),
    render: (h) => h(App),
}).$mount("#app");
